import {
    // getRoleIdFromRoleName,
    makeid
} from '../helpers/Helpers'
// import jwtDecode from "jwt-decode";

//todo fix passing the params for preocess
export default {
    'name': process.env.APP_NAME,
    'api': {
        // 'endpoint': "https://testapi.stockholmfilmfestival.se/", //"http://192.168.10.12:36969/"
        'endpoint': "https://stagingapi.stockholmfilmfestival.se/",
        // 'cdn':  "https://testapi.stockholmfilmfestival.se/", //"http://192.168.10.12:36969/",
        'cdn': "https://stagingapi.stockholmfilmfestival.se/",
        getCdnEndPoint() {
            if (this.cdn) {
                return this.cdn
            } else {
                return this.endpoint;
            }
        }
    },
    'language': process.env.LANGUAGE,
    'adminURL': document.getElementById('application').getAttribute('data-admin-url'),
    'frontURL': document.getElementById('application').getAttribute('data-front-url'),
    'client': document.getElementById('application').getAttribute('data-client'),
    'renderer': document.getElementById('application').getAttribute('data-renderer'),
    destroy(key){
        localStorage.removeItem(this.getStoragePrefixes(key));
    },
    set(key, value) {
        localStorage.setItem(this.getStoragePrefixes(key), value);
    },
    get(key) {
        let value = null;
        value = localStorage.getItem(this.getStoragePrefixes(key));

        if (value === "true") {
            return true;
        }
        if (value === "null") {
            return null;
        }
        if (value === "false") {
            return null;
        }

        return value;
    },
    clearStorage() {
        this.destroy('apitoken');
        this.destroy('user');
        this.destroy('roleId');
        this.destroy('ssoId');
    },
    setToken(token) {
        this.set('apitoken', token);
    },
    getToken(originalToken) {
        let token = this.get('apitoken');
        return token;
    },
    getRole() {
        let role = this.get('roleId');
        return role;
    },
    setRole(roleId) {
        return this.set('roleId', roleId);
    },
    setUser(userObj){
        if(userObj){
            this.set('user', JSON.stringify(userObj));
        } else {
            this.destroy('user');
        }
    },
    getUser(){
        let user = this.get('user');
        if (user){
            return JSON.parse(user);
        }
        return null;
    },
    getApiConfig(params, originalToken) {
        let h = {
            'headers': {
                'pragma': 'no-cache'
            },
            // 'auth': {
            //     'username': 'awave',
            //     'password': 'awave'
            // }
        };
        if (params) {
            Object.keys(params).map((key) => {
                h[key] = params[key];
                return null;
            });
        }
        h.headers = {
            ...h.headers,
            'Content-Type': 'application/json-patch+json',
            'accept': 'application/json'
        }

        let token = this.getToken();

        // console.log(token)

        if (token) {
            h.headers = {
                ...h.headers,
                'Authorization': 'Bearer ' + token
            }
        }

        //console.log("headers", h);

        return h;
    },
    getId() {
        let id = localStorage.getItem("id");
        if (!id) {
            id = this.generateId();
        }
        return id;
    },
    getStoragePrefixes(key) {
        let id = this.getId();
        return id + this.renderer + this.client + key;
    },
    generateId() {
        var id = makeid();
        localStorage.setItem("id", id);
        return id;
    },
    getCurrentUser() {
        let user= this.getUser();

        if (this.managerViewOn()) {
            let manageAsObj = this.get("manageAs");

            if (manageAsObj){
                manageAsObj = JSON.parse(manageAsObj);
                user.guid = manageAsObj.guid;
                user.userName = manageAsObj.userName;
                user.userId = manageAsObj.userId;
            }
        }

        return user;
    },
};